<template>
  <div id='MCN'>
    <!--    <img src='../assets/mcn/mcn-banner.png' alt='' class='banner-img'>-->
    <div class='banner_1'>
      <div class='swiper_content'>
        <!--        <div class="swiper-container">-->
        <!--        <swiper :options='swiperOptions1'>-->
        <!--          <swiper-slide v-for='(item,index) in logoList' :key='index'>-->
        <div v-if='logoList.length > 0'>
          <a :href='logoList[0].link_url&&logoList[0].link_url.indexOf("http") >= 0  ? logoList[0].link_url : null' target='_blank'><img :src='logoList[0].img'/></a>
        </div>
        <!--          </swiper-slide>-->
        <!-- 如果需要分页器 slot插槽 会自动往里面渲染分页器-->
        <!--                      <div class="swiper-pagination" slot="pagination"></div>-->
        <!-- 如果需要导航按钮 -->
        <!--          <div class='swiper-button-prev prev1' slot='button-prev'></div>-->
        <!--          <div class='swiper-button-next next1' slot='button-next'></div>-->
        <!--        </swiper>-->
        <!--      </div>-->
      </div>
    </div>
    <div class='banner_introduction'>
      <div class='box_introduction' style="position: relative">
        <div class='box_introduction_title'>
          <div class='introduction_title'>华星本地生活</div>
          <!--          <img src='../assets/home/logo-img.png'/>-->
        </div>
        <div class='introduction_content'>
          <div class='paragraphs'>
            <div>{{textJson.length>0 ? textJson[0].content : ''}}</div>
          </div>
          <div class='paragraphs_img'>
            <img v-if='textJson.length>0 && textJson[0].img' :src='textJson[0].img'/>
<!--            <img v-else src='../assets/critical.png'/>-->
          </div>
        </div>
      </div>
    </div>
    <!--    头部达人-->
    <div class='head-info-item'>
      <div class='header-anchor'>
        <div style='margin: 30px 0 30px 0;text-align: center;'>
          <span>达人展示</span>
        </div>
        <div class='anchor-content-info headMaster'>
          <div class="swiper swiper1 swiperDaren">
            <div class="swiper-wrapper">
              <div class="swiper-slide swiper-slide1" v-for='(item,index) in header_anchor' :key='index'>
                    <a :href='item.link_url && item.link_url.indexOf("https") >= 0  ? item.link_url : null' target='_blank' style='width: 100%'>
                      <img :src='item.img' alt='' class="anchor-avatar" style='width: 100%'>
                    </a>
              </div>
            </div>
            <!-- 如果需要分页器 -->
            <div class="swiper-pagination"></div>

            <!-- 如果需要导航按钮 -->
            <div class="swiper-button-prev swiper-button-top swiper-top2"></div>
            <div class="swiper-button-next swiper-button-bottom swiper-bottom2"></div>
          </div>
        </div>
      </div>
    </div>
    <!--    爆款视频-->
    <div class='head-info-item' style='background: #F6F6F6;'>
      <div class='header-anchor' style='width: 62%;position: relative'>
        <!--          position: relative;
          .video-player{
            position: absolute;
            width: 376px;
            height: 700px;
            z-index: 2999!important;
            top: -120px;
            left: calc(50% - 188px);
          }-->
        <div style='margin: 30px 0 30px 0;text-align: center;'>
          <span>爆款案例展示</span>
        </div>
        <div class='popular-video'>
          <div class="swiper swiper2">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for='(item, index) in popular_video' :key='index' style='position: relative' >
<!--                @click='showVideoSrc(item,index)'-->
                <!--                <a target='_blank' style='height: 471px;width: 232px;' :href='item.link_url.indexOf("http") >= 0  ? item.link_url : null'>-->
                <img :src='item.img' alt='' class="avatar-video" :data-card="JSON.stringify(item)">
                <!--                </a>-->
                <div class='video-button'>
                  <i class='el-icon-video-play' :data-card="JSON.stringify(item)"></i>
                </div>
              </div>
            </div>
            <!-- 如果需要导航按钮 -->
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
          </div>
          <el-dialog
            :visible.sync="dialogVisible"
            width='376px'
            height="700px"
            :show-close="false"
            custom-class='video-dialog-s'
            :modal='false'
            @close='closeChange'
            top="15vh">
            <video-player class="video-player vjs-custom-skin" v-if='videoSrc'
                          ref="videoPlayer"
                          :playsinline="false"
                          :options="playerOptions">
            </video-player>
          </el-dialog>
        </div>
      </div>
    </div>
    <!--    广告爆款达人-->
    <div class='head-info-item banner_MCN'>
      <div class='header-anchor mobile-terminal-header'>
        <div style='margin: 30px 0 30px 0;text-align: center;'>
          <span>服务案例</span>
        </div>
          <div class='mcn_swiper'>
            <swiper :options='swiperOptionsMcn'>
              <swiper-slide v-for='(item,index) in pop_advertising' :key='index'>
                <a :href='item.link_url&&item.link_url.indexOf("http") >= 0  ? item.link_url : null' target='_blank' style='width: 100%;height: 570px'>
                  <img style='width: 100%;height: 570px' :src='item.img'/></a>
              </swiper-slide>
              <!-- 如果需要分页器 slot插槽 会自动往里面渲染分页器-->
              <div class="swiper-pagination" slot="pagination"></div>
              <!-- 如果需要导航按钮 -->
            </swiper>
            <div class='swiper-button-prev prevMcn' slot='button-prev'></div>
            <div class='swiper-button-next nextMcn' slot='button-next'></div>
          </div>
      </div>
    </div>
    <div class='brand_wrapper'>
      <div class='brand_title'>合作品牌</div>
      <div class='brand_content'>
        <vue-seamless-scroll :data="brandList" :class-option="defaultOption"  class="seamless-warp2">
          <ul class="item">
            <li v-for="(item ,index) in brandList" style='margin-top: 30px' :key='index'>
              <img :src='item.img' />
            </li>
          </ul>
        </vue-seamless-scroll>
      </div>
    </div>
    <div class='recruit-item'>
      <div class="content-s">
        <div class='form-item-s'>
          <div class="name-d">商务合作</div>
          <el-form ref="form" :model="form" :rules='rules'  label-position="left">
            <div class="back-s">
              <el-form-item label="" prop='brand_name'>
                <el-input v-model="form.brand_name" size='mini' :placeholder="$t('Local.brand_name')"></el-input>
              </el-form-item>
            </div>
            <div class="back-s">
              <el-form-item label="" prop='MerchantScale'>
                <el-input v-model="form.MerchantScale" size='mini' :placeholder="$t('Local.MerchantScale')"></el-input>
              </el-form-item>
            </div>
            <div class="back-s">
              <el-form-item label="" prop='wechat'>
                <el-input v-model="form.wechat" size='mini' :placeholder="$t('LIVE.wechat')"></el-input>
              </el-form-item>
            </div>
            <div class="back-s">
              <el-form-item label="" prop='phone'>
                <el-input v-model="form.phone" size='mini' :placeholder="$t('LIVE.phone')"></el-input>
              </el-form-item>
            </div>
            <el-form-item label-width='0' style='text-align: center;margin-top: 110px'>
              <el-button type="primary" size='small' @click="onSubmit('form')">{{$t("LIVE.submitText")}}</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Swiper from 'swiper'
  import vueSeamlessScroll from 'vue-seamless-scroll'
  import {ContentData,businessCooperate} from '@/api/mcn'
  import { Logo} from '@/api/banner'
  let vm = null;
  export default {
    name: 'LocalLife',
    components: {
      vueSeamlessScroll
    },
    data() {
      return{
        brandList:[],
        logoList:[],
        Index:0,
        swiperOptions1: {
          // 自动播放
          autoplay: {
            delay: 3000,
          },
          // 环路播放
          loop: true,
          // 切换效果
          effect: 'slide',
          cubeEffect: {
            slideShadows: true,
            shadow: true,
            shadowOffset: 100,
            shadowScale: 0.6
          },
          // 前进后退按钮
          navigation: {
            nextEl: '.swiper-button-next.next1',
            prevEl: '.swiper-button-prev.prev1',
          }
        },
        swiperOptions2: {
          // 自动播放
          autoplay: {
            delay: 3000,
          },
          // 环路播放
          loop: true,
          // 切换效果
          effect: 'slide',
          cubeEffect: {
            slideShadows: true,
            shadow: true,
            shadowOffset: 100,
            shadowScale: 0.6
          },
          // 前进后退按钮
          navigation: {
            nextEl: '.swiper-button-next.next1',
            prevEl: '.swiper-button-prev.prev1',
          }
        },
        swiper: {
          direction: 'vertical', // 垂直切换选项
          loop: false, // 循环模式选项
          slidesPerColumn: 3, //竖着展示3张图片
          slidesPerGroup: 1,
          slidesPerColumnFill:'row',
          // slidesPerGroupSkip: 4,
          // slidesPerGroup: 3,
          // 如果需要分页器
          pagination: {
            el: '.swiper-pagination',
            bulletClass : 'my-bullet',//需设置.my-bullet样式
            bulletActiveClass: 'my-bullet-active',
            clickable :true,
          },

          // 如果需要前进后退按钮
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          // 如果需要滚动条
          scrollbar: {
            el: '.swiper-scrollbar',
          },
          autoplay: {
            delay: 5000,
            stopOnLastSlide: false,
            disableOnInteraction: false,
          },
          initialSlide :0,
          observer:true,//修改swiper自己或子元素时，自动初始化swiper
          observeParents:true//修改swiper的父元素时，自动初始化swiper
        },
        swiper2: {
          loop: true, // 循环模式选项
          // 如果需要分页器
          pagination: {
            el: '.swiper-pagination',
            bulletClass : 'my-bullet-2',//需设置.my-bullet样式
            bulletActiveClass: 'my-bullet-active',
            clickable :true,
          },
          // 如果需要前进后退按钮
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          slidesPerView: 5,
          slidesPerGroup: 1,
          autoplay: {
            delay: 5000,
            stopOnLastSlide: false,
            disableOnInteraction: false,
          },
          onlyExternal:true,
          on: {
            click: function (e)  {
            }
          },
          initialSlide :0,
          observer:true,//修改swiper自己或子元素时，自动初始化swiper
          observeParents:true//修改swiper的父元素时，自动初始化swiper
        },
        swiperOptionsMcn: {
          // 自动播放
          autoplay: true,
          // 环路播放
          loop: true,
          pagination: {
            el: '.swiper-pagination',
            bulletClass : 'my-bullet-2',//需设置.my-bullet样式
            bulletActiveClass: 'my-bullet-active',
            clickable :true,
          },
          // 切换效果
          effect: 'slide',
          cubeEffect: {
            slideShadows: true,
            shadow: true,
            shadowOffset: 100,
            shadowScale: 0.6
          },

          // 前进后退按钮
          navigation: {
            nextEl: '.swiper-button-next.nextMcn',
            prevEl: '.swiper-button-prev.prevMcn',
          },
          observer: true,//修改swiper自己或子元素时，自动初始化swiper
          observeParents: true//修改swiper的父元素时，自动初始化swiper
        },
        swiper3: {
          loop: true, // 循环模式选项
          // 如果需要分页器
          pagination: {
            el: '.swiper-pagination',
            bulletClass : 'my-bullet-2',//需设置.my-bullet样式
            bulletActiveClass: 'my-bullet-active',
            clickable :true,
          },
          // 如果需要前进后退按钮
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          slidesPerView: 1,
          slidesPerGroup: 1,
          roundLengths : true,
          autoplay: true,
          initialSlide :0,
          observer:true,//修改swiper自己或子元素时，自动初始化swiper
          observeParents:true//修改swiper的父元素时，自动初始化swiper
        },
        form: {
          brand_name: '', // 直播平台
          MerchantScale: '', // 直播账号
          wechat: '', // 擅长领域
          phone: '', // 微信号
        },
        img_banner: '',
        header_anchor: [], // 头部达人
        popular_video: [], // 爆款视频
        pop_advertising: [], // 广告爆款达人
        videoSrc:"",
        playerOptions:{},
        dialogVisible: false,
        newsList: [
          {
            'img': 'https://img0.baidu.com/it/u=617215503,188424665&fm=253&fmt=auto&app=138&f=JPG?w=120&h=120'
          },
          {
            'img': 'https://img2.baidu.com/it/u=878588444,1785929995&fm=253&fmt=auto&app=138&f=JPG?w=120&h=120'
          },
          {
            'img': 'https://img2.baidu.com/it/u=4276295978,1564416943&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },
          {
            'img': 'https://img0.baidu.com/it/u=503580633,3567414311&fm=253&fmt=auto&app=120&f=JPEG?w=120&h=120'
          },
          {
            'img': 'https://img1.baidu.com/it/u=417744379,2733026919&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },
          {
            'img': 'https://img2.baidu.com/it/u=3793272314,3579832883&fm=253&fmt=auto&app=138&f=PNG?w=120&h=120'
          },
          {
            'img': 'https://img0.baidu.com/it/u=365019288,3680240542&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },
          {
            'img': 'https://img2.baidu.com/it/u=2878383006,2396702772&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },
          {
            'img': 'https://img1.baidu.com/it/u=4110101154,77755075&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },
          {
            'img': 'https://img0.baidu.com/it/u=2413108766,1138688916&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },
          {
            'img': 'https://img1.baidu.com/it/u=2246582671,1076022454&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },
          {
            'img': 'https://img1.baidu.com/it/u=2439673203,2602330429&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },
          {
            'img': 'https://img1.baidu.com/it/u=4087371483,2459403015&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },
          {
            'img': 'https://img0.baidu.com/it/u=4004226857,4015547609&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },
          {
            'img': 'https://img0.baidu.com/it/u=728270901,3143689237&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },
          {
            'img': 'https://img1.baidu.com/it/u=166996070,3813994298&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },
          {
            'img': 'https://img1.baidu.com/it/u=1229670147,54142161&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },
          {
            'img': 'https://img1.baidu.com/it/u=3329779680,1730727527&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },
          {
            'img': 'https://img2.baidu.com/it/u=95201979,4265305100&fm=253&fmt=auto&app=138&f=PNG?w=120&h=120'
          },
          {
            'img': 'https://img1.baidu.com/it/u=2667643370,4182542990&fm=253&fmt=auto&app=138&f=GIF?w=120&h=120'
          },
          {
            'img': 'https://img1.baidu.com/it/u=721165360,1838369749&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },
          {
            'img': 'https://img1.baidu.com/it/u=3024818213,924427534&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },
          {
            'img': 'https://img2.baidu.com/it/u=2279560675,1435387031&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },
          {
            'img': 'https://img2.baidu.com/it/u=1775013149,858928440&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },   {
            'img': 'https://img0.baidu.com/it/u=2525444497,3926769801&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },   {
            'img': 'https://img1.baidu.com/it/u=1622699731,2387912926&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },   {
            'img': 'https://img1.baidu.com/it/u=2603763568,553613880&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },   {
            'img': 'https://img0.baidu.com/it/u=897610780,2733025773&fm=253&fmt=auto&app=138&f=PNG?w=120&h=120'
          },   {
            'img': 'https://img0.baidu.com/it/u=1671708499,2457454968&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },   {
            'img': 'https://img0.baidu.com/it/u=2966861195,1513133659&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },   {
            'img': 'https://img0.baidu.com/it/u=3619946645,769941788&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },   {
            'img': 'https://img1.baidu.com/it/u=2568346834,347660496&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },   {
            'img': 'https://img1.baidu.com/it/u=2725804151,3072200411&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },   {
            'img': 'https://img1.baidu.com/it/u=596175957,2171475362&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },   {
            'img': '1111111'
          },
        ],
        defaultOption:{
          step: 0.8, // 数值越大速度滚动越快
          limitMoveNum: 2,
          hoverStop: true, // 是否开启鼠标悬停stop
          direction: 2, // 0向下 1向上 2向左 3向右
          // openWatch: true, // 开启数据实时监控刷新dom
          // singleHeight: -0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
          // singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
          // waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
        },
        textJson:[]
      }
    },
    computed:{
      rules:function(){
        let data={
          brand_name: [
            { required: true, message: this.$t('LIVE.rules_brand'), trigger: 'blur' }
          ],
          MerchantScale: [
            { required: true, message: this.$t('LIVE.rules_MerchantScale'), trigger: 'blur' }
          ],
          wechat: [
            { required: true, message:  this.$t('LIVE.rules_wechat'), trigger: 'blur' }
          ],
          phone: [
            { required: true, message: this.$t('LIVE.rules_phone'), trigger: 'blur' }
          ],
        }
        return data;
      },
      rules_1:function(){
        let data= {
          brand: [
            { required: true, message: this.$t('LIVE.rules_brandText'), trigger: 'blur' }
          ],
          live_id: [
            { required: true, message: this.$t('LIVE.rules_intention'), trigger: 'blur' }
          ],
          desc: [
            { required: true, message: this.$t('LIVE.rules_cooperationNeeds'), trigger: 'blur' }
          ],
          is_brand: [
            { required: true, message: this.$t('LIVE.rules_brandDirectSale'), trigger: 'blur' }
          ],
          wechat: [
            { required: true, message: this.$t('LIVE.rules_wechat'), trigger: 'blur' }
          ],
          city: [
            { required: true, message: this.$t('LIVE.rules_phone'), trigger: 'blur' }
          ]
        }
        return data;
      },

    },
    mounted() {
      this.get_Logo()
      this.get_ContentData(1)
      this.get_ContentData(2)
      this.get_ContentData(3)
      this.get_ContentData(4)
      this.get_ContentData(5)
      let  language=localStorage.getItem('locale')
    },
    created() {
      vm = this;
    },
    methods: {
      get_ContentData: function(data) {
        ContentData({ type: data }).then(resp => {
          if(resp.status == 200) {
            if(data == 1) {
              this.$set(this, 'textJson', resp.data)
            }
            if(data == 2) {
              this.$set(this, 'header_anchor', resp.data)
              // this.$set(this, 'pop_advertising', resp.data)//测试
              this.$nextTick(()=> {
                let mySwiper = new Swiper('.swiper1', {
                  direction: 'vertical', // 垂直切换选项
                  loop: true, // 循环模式选项
                  // 如果需要分页器
                  pagination: {
                    el: '.swiper-pagination',
                    bulletClass : 'my-bullet',//需设置.my-bullet样式
                    bulletActiveClass: 'my-bullet-active',
                    clickable :true,
                    // dynamicBullets: true,
                    // dynamicMainBullets: 3,
                  },

                  // 如果需要前进后退按钮
                  navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                  },
                  // 如果需要滚动条
                  scrollbar: {
                    el: '.swiper-scrollbar',
                  },
                  autoplay: {
                    delay: 5000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false,
                  },
                  initialSlide :1,
                  observer:true,//修改swiper自己或子元素时，自动初始化swiper
                  observeParents:true//修改swiper的父元素时，自动初始化swiper
                },)
              })

            }
            if(data == 3) {
              this.$set(this, 'popular_video', resp.data)
              this.$nextTick(()=> {
                let mySwiper2 = new Swiper('.swiper2', {

                  loop: true, // 循环模式选项
                  // 如果需要分页器
                  pagination: {
                    el: '.swiper-pagination',
                    bulletClass : 'my-bullet-2',//需设置.my-bullet样式
                    bulletActiveClass: 'my-bullet-active',
                    clickable :true,
                  },
                  // 如果需要前进后退按钮
                  navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                  },
                  slidesPerView: 5,
                  slidesPerGroup: 1,
                  autoplay: {
                    delay: 5000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false,
                  },
                  onlyExternal:true,
                  on: {
                    click: function (e)  {
                      vm.showVideoSrc(e)
                    }
                  },
                  initialSlide :0,
                  observer:true,//修改swiper自己或子元素时，自动初始化swiper
                  observeParents:true//修改swiper的父元素时，自动初始化swiper

                })
              })
            }
            if(data == 4) {
              this.$set(this, 'pop_advertising', resp.data)
              this.$nextTick(()=> {
              })
            }
            if(data == 5) {
              this.$set(this, 'brandList', resp.data)
            }
          } else {
            this.$message(resp.data.msg)
          }
        }).catch(err => {
        })
      },
      closeChange: function() {
        this.$refs.videoPlayer.player.pause() // 暂停
        // console.log(43444, this.$refs.videoPlayer.player.paused())
      },
      showVideoSrc:function(params){
        // console.log('4545454',JSON.parse(params.target.getAttribute('data-card')).img)
        // console.log('videoSrc',)
        let options={
          // playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
          playbackRates: false, //播放速度
          autoplay: true, //如果true,浏览器准备好时开始回放。
          muted: true, // 默认情况下将会消除任何音频。
          loop: true, // 导致视频一结束就重新开始。
          preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: "zh-CN",
          aspectRatio: "4:3", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          sources: [
            {
              type: "video/mp4",
              src:JSON.parse(params.target.getAttribute('data-card')).link_url
              // src: require("../assets/Index/video1.mp4"), //视频url地址
            },
          ],

          // poster: require("../assets/video_poster.png"), //你的封面地址
          poster:JSON.parse(params.target.getAttribute('data-card')).img, //你的封面地址
// width: document.documentElement.clientWidth,
          notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
          controlBar: {
            timeDivider: true, // 时间分割线
            durationDisplay: true, // 总时间
            remainingTimeDisplay: true, // 剩余播放时间
            progressControl: true, // 进度条
            fullscreenToggle: false  // 全屏按钮
          }
        }
        this.$set(this,'videoSrc',JSON.parse(params.target.getAttribute('data-card')).link_url)
        this.$set(this,'playerOptions',options)
        this.dialogVisible = true
      },
      get_Logo: function() {
        Logo(10).then(resp => {
          if (resp.status == 200) {
            this.$set(this, 'logoList', resp.data)
          }
        }).catch(error => {

        })
      },
      prevChange:function(){
        // console.log('9999',this.Index)
      },
      // 达人招募 -- 提交
      onSubmit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let obj = {
              brand_name:this.form.brand_name,
              shop_scale:this.form.MerchantScale,
              wechat:this.form.wechat,
              phone:this.form.phone
            }
            businessCooperate(obj).then(resp => {
              if(resp.status == 200) {
                this.$message({
                  message: '提交成功',
                  type: 'success'
                });
                this.form = {
                  brand_name: '', // 直播平台
                  MerchantScale: '', // 直播账号
                  wechat: '', // 擅长领域
                  phone: '', // 微信号
                }
              } else {
                this.$message(resp.msg)
              }
            }).catch(err => {
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
    }
  }
</script>

<style scoped lang='scss'>
  .head-info-item{
  //display: flex;
    position: relative;
    padding: 30px 0;
  .header-anchor{
    width: 64%;
    margin: auto;
    font-size: 26px;
    font-family: SourceHanSansSC;
    font-weight: 400;
    color: #333;
    position: relative;
    line-height: 30px;
  .video-player{
    position: absolute;
    width: 376px;
    height: 650px;
    z-index: 2999!important;
    top: -40px;
    left: calc(50% - 188px);
    transition: all 2s;
  }
  .images{
    width: 281px;
    position: absolute;
    left: 50%;
  }
  .advertising-img{
    width: 340px;
  }
  .anchor-content-info{
    height: 428px;
    width: 1200px;
    overflow: hidden;
    margin: auto;
  .avatar2{
    width: 343px;
    height: 100%;
  //background: rgba(213, 71, 36, 0.1);
    border-radius: 10px;
    cursor: pointer;
  }
  .nickname-s{
    position: absolute;
    bottom: 0;
    color: #fff;
    line-height: 45px;
    font-size: 14px;
    font-family: SourceHanSansSC;
    font-weight: 500;
    width: 92%;
    display: flex;
    justify-content: space-between;
    background-color: rgba(213, 71, 36, 0.2);
    padding: 0 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .swiper-slide {
    width: 30% !important;
    height: 428px;
  }
  .swiper-slide1{
    width: 100% !important;
    border-radius: 16px;
  }
  }
  .address-word{
    width: 181px;
  }
  }
  .rectangle-position{
    position: absolute;
    bottom: 0;
    left: 60px;
  img{
    width: 104px;
    height: 73px;
  }
  }
  .rectangle-right{
    position: absolute;
    top: 80px;
    right: 100px;
  img{
    width: 106px;
    height: 73px;
  }
  }
  .rights{
    right: 160px;
  }
  .swiper-slide{
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-family: SourceHanSansSC;
    font-weight: 400;
    color: #666666;
  }
  .anchor-list{
    padding: 30px 0 0 70px;
  }
  .anchor-list div:nth-child(1){
    font-size: 20px;
    color: #333333;
    line-height: 50px;
  }
  .anchor-list div:nth-child(3){
    margin: 50px 0 150px 0;
  }
  .anchor-list span:nth-child(4){
    background: #D54724;
    border-radius: 23px;
    padding: 8px 20px;
    color: #fff;
    font-size: 16px;
    font-family: SourceHanSansSC;
    font-weight: 400;
  }
  .anchor-avatar{
    width: 428px;
    height: 100%;
    border-radius: 16px;
  }
  }
  .banner_1 {
    width: 100%;
    height: 700px;

  .swiper_content {
    width: 100%;
    height: 700px;

  .swiper-container {
    width: 100%;
    height: 700px;

  .swiper-wrapper {
    width: 100%;
    height: 700px;

  .swiper-slide {
    width: 100%;
    height: 700px;

  img {
    width: 100%;
    height: 700px;
  }
  }
  }
  }
  img {
    width: 100%;
    height: 700px;
  }
  }
  }
  .list-swiper-s{
    position: relative;
  //border: 1px solid #D54724;
  //box-shadow: 2px 3px 10px 0px rgba(34, 20, 22, 0.16);
    border-radius: 10px;
  a{
    width: 342px;
    height: 100%;
  }
  }
  .list-swiper-s:hover{
    background: #D54724;
    box-shadow: 2px 3px 10px 0px rgba(34,20,22,0.16);
  }
  .left-ellipse{
    width: 505px;
    height: 724px;
    position: absolute;
    bottom: -120px;
    left: 0;
  }
  .right-semicircle{
    width: 338px;
    height: 624px;
    position: absolute;
    top: -50px;
    right: 0;
  }
  .hot-talent{
    display: flex;
    width: 1200px;
    height: 638px;
    overflow: hidden;
  .talent-list{
    text-align: center;
    font-size: 20px;
    font-family: SourceHanSansSC;
    font-weight: 400;
    color: #333333;
  .avatar-talent{
    width: 299px;
    height: 638px;
  //background: #F5F6F8;
  //border: 1px solid #E84B24;
  //box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.2);
  }
  .line{
    width: 25px;
    height: 2px;
    background: #E84B24;
    margin: 10px auto;
    margin-top: 40px;
  }
  .line-s{
    width: 25px;
    height: 2px;
    background: #E84B24;
    margin: 25px auto;
  }
  .nickname-item{
    text-align: center;
  }
  }
  }
  .popular-video{
    height: 501px;
    width: 100%;
    overflow: hidden;
    margin: auto;

  .avatar-video{
    height: 470px;
    width: 230px;
  //border: 1px solid #E84B24;
    border-radius: 30px;
    cursor: pointer;
  }
  .swiper-button-prev{
    left: -60px;
  }
  .swiper-button-next{
    right: -60px;
  }
  .video-button{
    position: absolute;
    width: 46px;
    height: 46px;
    top:calc(50% - 23px);
    left:calc(50% - 23px);
    cursor: pointer;
  i{
    color: #fff;
    font-size: 46px;
    font-weight: 200
  }
  }
  }
  .pop-advertising{
    height: 438px;
    display: flex;
    margin-bottom: 40px;
    overflow: hidden;
  .talent-info-s{
    font-size: 14px;
    font-family: SourceHanSansSC;
    font-weight: 400;
    color: #666666;
  //background: #F6E8E4;
    border-radius: 40px 0px 0px 40px;
    padding: 40px 0 40px 40px;
    width: 35%;
  }
  .talent-contents{
  //width: 50%;
    margin:auto;
  }
  .talent-contents > div:nth-child(2){
    padding-left: 70px;
  }
  .nickname-info {
    font-size: 18px;
    font-family: SourceHanSansSC;
    font-weight: 400;
    color: #333333;
  }
  .info-avatar{
    width: 200px;
    height: 200px;
    border-radius: 50% 50%;
  }
  .cooperative-brands{
    width: 97%;
    background: #D54724;
    border-radius: 40px 40px 40px 40px;
    display: flex;
    font-size: 16px;
    font-family: SourceHanSansSC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 20px;
    padding: 10px;
  .brands-s{
    overflow: hidden;
    margin: 0 15px;
  }
  }
  .avatar-advertising{
    width: 218px;
    height: 100%;
    border-radius: 30px;
  }
  }
  .quality_cases{
    height: 576px;
    width: 94%;
    overflow: hidden;
    margin: auto;
  //background: #F2F2F2;
    border-radius: 40px 40px 40px 40px;
  //box-shadow: 0px 0px 7px 0px rgba(60,18,7,0.1500);
  .yanxuan_pagination{
    right: -10px;
  }
  .swiper-slide{
    height: auto;
    width: 100%;
  }
  }
  .lists-con{
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 14px;
    font-family: SourceHanSansSC;
    font-weight: 400;
    color: #FFFFFF;
  }
  .yanxuan-avatar{
    width: 96.5%;
    height: 446px;
  }
  .cases-list{
    height: 446px;
    width: 100%;
  }
  .list-swiper-brands{
    position: relative;
    border: 2px solid #fff;
    border-radius: 30px;
    width: 80%;
    display: flex;
  .nickname-brands{
    position: absolute;
    background: #D54724;
    border-radius: 33px;
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 14px;
  }
  .name-title{
  //height: 90px;
  //width: 20px;
    line-height: 20px;
    text-align: center;
    padding: 6px 6px;
    margin-right: 30px;
  span{
    color: #D54724;
    background-color: #F6E8E4;
    padding: 6px 6px;
    display: inline-block;
  }
  }
  .brand-list-s{
    position: relative;
  }
  .top_{
    position: absolute;
    top:3px;
    left: 10px;
    width: 75%;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 18px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #262626;
    display: none;
    animation: dong 1s;
  .top-title-n{
    display: flex;
    justify-content: space-between;
  }
  .cpm-data{
    font-size: 16px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #D54724;
  }
  }
  .brand-list-s:hover .top_{
    display: block;
  }
  @keyframes dong {
    0% {
      transform: translate(0px, -20px);
    }
    100% {
      transform: translate(0px, 0px);
    }
  }
  }
  .recruit-item{
    height: 700px;
    width: 100%;
    background: url("../assets/local.png") no-repeat;
    background-size:100% 700px;
    position: relative;
  img{
    height: 700px;
    width: 100%;
  }
  .content-s{
    width: 55%;
    margin: auto;
  //padding-top: 120px;
    position: absolute;
    top:120px;
    z-index: 2;
    left: 22%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .form-item-s{
    width: 283px;
    background: #fff;
    box-shadow: 0px 0px 8px 0px rgba(170, 169, 169, 0.44);
    border-radius: 40px;
    height: 450px;
    padding: 15px;
  .back-s{
  //background: #F19F8A;
  //border-radius: 25px;
  //padding: 0 10px 0 10px;
  }
  }
  .form-item-s .name-d {
    font-size: 26px;
    font-family: SourceHanSansSC;
    font-weight: 400;
    color: #333333;
    text-align: center;
    line-height: 50px;
    margin-bottom: 10px;
  }
  .data-see{
    padding: 10px;
  .image-platform{
    width: 439px;
    height: 268px;
  }
  }
  }
  .swiper1 {
    width: 1046px;
    height: 428px;
    /*margin-left: -100px;*/
    --swiper-theme-color: rgba(232, 75, 36, 1);
    --swiper-navigation-size: 40px;/* 设置按钮大小 */
  }
  .swiper2{
    width: 100%;
    height: 471px;
    --swiper-theme-color: rgba(232, 75, 36, 1);
    --swiper-navigation-size: 40px;/* 设置按钮大小 */
  }
  .swiper3{
    width: 97%;
    height: 438px;
    --swiper-theme-color: rgba(232, 75, 36, 1);
    --swiper-navigation-size: 40px;/* 设置按钮大小 */
  }
  .swiper5 {
    width: 97%;
    height: 576px;
    --swiper-theme-color: rgba(232, 75, 36, 1);
    --swiper-navigation-size: 40px;/* 设置按钮大小 */
  }
  .map-container {
    width: 100%;
    height: 100%;
    border-bottom-right-radius: 30px;
    border-top-right-radius: 30px;
    background-color: rgba(213, 71, 36, 0.1);
  }
  .button-brands-left{
    left:-2%;
  }
  .button-brands-right{
    right:-5%;
  }
  .swiper_bottom,.swiper_top{
    left: 98% !important;
  }
  .headMaster{
  .swiper-slide{
    width: auto!important;
  }
  }

  .banner_introduction {
    box-sizing: border-box;
    /*margin-top: 90px;*/
    width: 100%;
    min-height: 350px;
    /*background-color: #F6F6F6;*/
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

  .box_introduction {
    width: 1205px;
    /*height: 490px;*/
    /*padding: 40px 0;*/
  //background-color: rgba(213, 71, 36, 0.1);
  //border-radius: 40px;
    box-sizing: border-box;
  //padding: 0px 40px 60px 40px;
    font-family: SourceHanSansCNVF-Regular;

  .box_introduction_title {
    margin-top: 50px;
    width: 100%;
    position: relative;
    font-family: SourceHanSansCNVF-Regular;
    text-align: center;


  .introduction_title {
    /*width: 120px;*/
    height: 25px;
    font-size: 26px;
    font-family: SourceHanSansCNVF-Regular;
    font-weight: 500;
    color: #333333;
    line-height: 50px;
    /*margin-left: calc(50% - 60px);*/
  }

  }

  .introduction_content {
    margin-top: 60px;
    font-size: 18px;
    font-family: SourceHanSansCNVF-Regular;
    font-weight: 600;
    color: #4D4D4D;
    line-height: 28px;

  .paragraphs {
    /*text-indent: 32px;*/
    margin-bottom: 20px;
    font-size: 16px;
    font-family: SourceHanSansCNVF-Regular;
    font-weight: 400;
    color: #4D4D4D;
    line-height: 38px;
    /*word-wrap: break-word;white-space : normal;*/
    white-space: pre-wrap !important;
    word-wrap: break-word !important;
    *white-space: normal !important;
    text-align: justify;
  }
  .paragraphs_img{
    width: 100%;
    height: 304px;
  img{
    width: 100%;
    height: 304px;
  }
  }
  pre{
    text-indent: 32px;
    white-space: pre-wrap;           /* css-3 */
    white-space: -moz-pre-wrap;      /* Mozilla, since 1999 */
    white-space: -pre-wrap;          /* Opera 4-6 */
    white-space: -o-pre-wrap;        /* Opera 7 */
    word-wrap: break-word;           /* Internet Explorer 5.5+ */
  }
  }

  }
  }

  .banner_MCN {
    padding-top: 68px!important;
    padding-bottom: 0px!important;
    box-sizing: border-box;
    margin-bottom: 80px;
    padding-left: 0px !important;
  .mcn_swiper{
    width: 100%;
    height: 620px;
  //border: 1px solid #D54724;
    border-radius: 40px;
    margin-right: 57px;
    position: relative;
    margin-top: 65px!important;
  .swiper-container{
    width: 1020px;
    height: 620px;
  .swiper-slide{
    width: 1020px;
    height: 570px;
    img{
      width: 1020px;
      height: 570px;
    }
  }
  }

  }
  .swiper-button-prev{
    left: -60px;
  }
  .swiper-button-next{
    right: -60px;
  }
  .swiper-container {
    width: 82%;
    height: 569px;
    border-radius: 40px;
  //border: 1px solid #D54724;
  //margin-right: 67px;

  .swiper-wrapper {
    width: 100%;
    height: 520px;
    border-radius: 40px;

  .swiper-slide {
    width: 100%;
    height: 520px;
    border-radius: 40px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 40px;
  }
  }
  }

  }


  .live_introduction_dot {
    margin-left: 200px;
  }
  }
  .brand_wrapper{
    width: 100%;
    background-color: #F5F5F5;
    padding-top: 15px;
    margin-top: 30px;
  .brand_title{
    text-align: center;
    font-size: 26px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #333333;
    line-height: 50px;
    margin-bottom: 10px;
  }
  .brand_content{
    width: 100%;
    /*background-color: #fff;*/
    height: 240px;
  .seamless-warp2{
    width: 100%!important;
    overflow: hidden;
  .item{
    width: 100%!important;
  li{
    width: 160px;
    height: 150px;
    display: flex;
    flex-flow: row nowrap;
    border-radius: 16px;
    align-items: center;
    margin-right: 15px;
    margin-bottom: 30px;
  /*.wrapper_img{*/
  /* width: 120px;*/
  /*  height: 120px;*/
  img{
    width: 160px;
    height: 150px;
    border-radius: 16px;
  }
  /*}*/
  }
  }
  }

  }
  }
</style>
